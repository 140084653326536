import NotificationItem, {
  NotificationItemProps,
} from 'components/infra/NotificationItem';
import React from 'react';
import styles from './NotificationList.module.scss';

export interface NotificationListProps {
  items: NotificationItemProps[];
}

function NotificationList({items}: NotificationListProps): JSX.Element {
  return (
    <div className={styles.wrapper}>
      {items &&
        items.map((item, index) => (
          <NotificationItem
            key={item.id}
            handleClick={item.handleClick}
            {...item}
            data-focusid={`notificationItem-${index}`}
            icon={item.icon}
          />
        ))}
    </div>
  );
}

export default NotificationList;
