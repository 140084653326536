import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import styles from './NotificationItem.module.scss';

export interface NotificationItemProps {
  id: number;
  title?: string;
  description: string;
  time: string;
  hasReceivedToday: boolean;
  opened: boolean;
  icon?: IconProp;
  handleClick?: () => void;
}

function NotificationItem({
  id,
  title,
  description,
  time,
  hasReceivedToday,
  opened,
  icon = ['fad', 'bell'],
  handleClick,
}: NotificationItemProps): JSX.Element {
  return (
    <div
      className={`${styles.item} $ ${opened ? styles.opened : ''}`}
      onClick={handleClick}
      onKeyPress={handleClick}
      role="button"
      tabIndex={id}
    >
      <article className={styles.content}>
        <span className={styles.iconWrapper}>
          <FontAwesomeIcon icon={icon} size="2x" color="#FFFFFF" />
        </span>
        <div className={styles.subcontent}>
          <header>
            <b className={styles.title}>{title}</b>
            <span className={styles.time}>
              <FontAwesomeIcon
                icon={hasReceivedToday ? ['fad', 'clock'] : ['fad', 'calendar']}
                size="1x"
                data-testid={hasReceivedToday ? 'clock' : 'calendar'}
                color="#4574B9"
              />
              {time}
            </span>
          </header>

          <p className={styles.description}>{description}</p>
        </div>
      </article>
    </div>
  );
}

export default NotificationItem;
