import React, {useEffect, createRef, ReactNode, useState} from 'react';
import NotificationButton, {
  NotificationState,
} from 'components/infra/NotificationButton';
import styles from './NotificationDropdown.module.scss';
import Button from '../Button';

export interface NotificationDropdownProps {
  children?: ReactNode;
  notificationState?: NotificationState;
  onClickSeeMore?: () => void;
  onClickFilterUnseenNotifications: () => void;
  isDropdownOpen: boolean;
  onClickNotificationButton: () => void;
  onClickOutsideDropdown: () => void;
  notificationCount: number;
}

function NotificationDropdown({
  notificationState,
  children,
  notificationCount,
  onClickSeeMore,
  isDropdownOpen,
  onClickNotificationButton,
  onClickOutsideDropdown,
  onClickFilterUnseenNotifications,
}: NotificationDropdownProps): JSX.Element {
  const [isUnseenNotificationActive, setIsUnseenNotificationActive] = useState(
    false,
  );

  const wrapperRef = createRef<HTMLDivElement>();

  useEffect(() => {
    function handleClickOutsideLabel(event: any): void {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickOutsideDropdown();
      }
    }

    document.addEventListener('click', handleClickOutsideLabel);

    return (): void => {
      document.removeEventListener('click', handleClickOutsideLabel);
    };
  }, [onClickOutsideDropdown, wrapperRef]);

  function onClickFilterButton() {
    setIsUnseenNotificationActive(!isUnseenNotificationActive);
    onClickFilterUnseenNotifications();
  }

  return (
    <div className={styles.notificationDropdown}>
      <div>
        <NotificationButton
          notificationState={isDropdownOpen ? 'showing' : notificationState}
          onClick={onClickNotificationButton}
          notificationCount={notificationCount}
        />
      </div>
      {isDropdownOpen && (
        <div
          className={styles.wrapperDropdownList}
          data-testid="notificationList"
          ref={wrapperRef}
        >
          <header className={styles.headerDropdown}>
            Notificações{' '}
            <Button
              text="Não Lidas"
              variant="secondary"
              backgroundColor="#ffffff"
              textColor="#000000"
              rightIcon={{
                icon: isUnseenNotificationActive
                  ? ['fas', 'square-check']
                  : ['far', 'square'],
                color: '#2A5082',
              }}
              onClick={() => onClickFilterButton()}
            />
          </header>
          <div className={styles.contentDropdown}>
            {children ? (
              <div className={styles.contentNotification}>{children}</div>
            ) : (
              <div data-testid="noContent" className={styles.noContent}>
                <p>Nenhuma notificação</p>
              </div>
            )}
          </div>
          <footer
            className={styles.footerDropdown}
            data-focusid="notificationDropdownFooter"
          >
            {onClickSeeMore && (
              <Button
                onClick={onClickSeeMore}
                variant="secondary"
                text="Ver tudo"
              />
            )}
            <Button
              onClick={onClickOutsideDropdown}
              variant="secondary"
              text="Fechar"
            />
          </footer>
        </div>
      )}
    </div>
  );
}

export default NotificationDropdown;
